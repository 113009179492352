<template>
  <div class="image" :style="style">
    <template v-if="!hideOverlay">
      <div class="tag-container">
        <div class="tag tag--top-brand" v-if="status === 'featured'">
          {{ $t('PRODUCTS.LABELS.FEATURED') }}
        </div>
        <div class="tag tag--top-brand" v-if="status === 'new'">
          {{ $t('PRODUCTS.LABELS.NEW') }}
        </div>
        <div class="tag tag--gift" v-if="isGift">
          <i class="far fa-gift"></i>
        </div>
      </div>
      <div class="purchased-tag" v-if="status === 'purchased'">
        {{ $t('PRODUCTS.LABELS.PURCHASED') }}
      </div>
      <div class="purchased-tag" v-if="status === 'gifted'">
        {{ $t('PRODUCTS.LABELS.GIFTED') }}
      </div>
      <div class="purchased-tag" v-if="status === 'registered'">
        {{ $t('PRODUCTS.LABELS.REGISTERED') }}
      </div>
      <div
        class="tag tag--daypass-price"
        v-if="product.day_pass_enabled && !static"
      >
        {{
          $t('PRODUCTS.LABELS.DAYPASS_PRICE', {
            price: $formatCurrency(product.day_pass_price || 0, currency),
          })
        }}
      </div>
      <div class="selectable-container" v-if="isSelectable">
        <HMCheckBox :modelValue="selected" @update:modelValue="$emit('click')" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { getDefaultThumbnailForProductType } from '@/utils/media';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import moment from 'moment';

export default {
  name: 'ProductThumbnail',
  props: {
    product: {
      type: Object as () => ProductResponse,
      required: true,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    static: {
      // removes any pricing information
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isGift: {
      type: Boolean,
      default: false,
    },
    fullSize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      currency: 'currency',
    }),
    style(): object | null {
      if (this.product.thumbnail_file) {
        const file = this.product.thumbnail_file as PhotoResponse;
        let thumbnailUrl = file.thumbnail;
        if (this.fullSize && file.image_url) thumbnailUrl = file.image_url;
        return {
          backgroundImage: 'url(' + thumbnailUrl + ')',
          backgroundSize: 'cover',
        };
      }
      if (this.product.cover_file) {
        const file = this.product.cover_file as PhotoResponse;
        let thumbnailUrl = file.thumbnail;
        if (this.fullSize && file.image_url) thumbnailUrl = file.image_url;
        return {
          backgroundImage: 'url(' + thumbnailUrl + ')',
          backgroundSize: 'cover',
        };
      }
      const thumbnailUrl = getDefaultThumbnailForProductType(
        this.product?.product_type
      );
      return {
        backgroundImage: 'url(' + thumbnailUrl + ')',
      };
    },
    status() {
      const isNew = moment(this.product.created).isAfter(
        moment().subtract({ days: this.product.media_new_tag_days })
      );
      if (this.product.gifted) {
        return 'gifted';
      } else if (
        this.product.purchased &&
        this.product.pricing_type === 'free'
      ) {
        return 'registered';
      } else if (this.product.purchased) {
        return 'purchased';
      } else if (this.product.is_featured) {
        return 'featured';
      } else if (isNew) {
        return 'new';
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

$tags-offset: 8px;
$tag-height: 26px;

.image {
  position: relative;
  border-radius: $border-radius;
  background-position: center center;
  background-size: auto 65%;
  background-repeat: no-repeat;
  background-color: var(
    --brand-color-with-opacity-03,
    rgba($light-bubblegum, 0.3)
  );
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.tag-container {
  display: flex;
  position: absolute;
  top: $tags-offset;
  left: $tags-offset;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  color: $white;
  height: $tag-height;
  padding: 2px 9px;
  font-size: 0.75rem;

  + .tag {
    margin-left: 6px;
  }

  &--top-brand {
    background-color: var(--brand-color, $coral);
  }

  &--gift {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  &--daypass-price {
    position: absolute;
    bottom: $tags-offset;
    left: $tags-offset;
    background-color: rgba($black, 0.5);
  }
}
.purchased-tag {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  backdrop-filter: blur(3px);
  height: 32px;
  background-color: rgba(black, 0.2);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
.inner-container {
  min-width: 66%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.selectable-container {
  min-width: 33%;
  width: 33%;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
</style>
