
<template>
  <div class="studio-product-card" v-bind="$attrs">
    <HMFixedAspectRatioContainer>
      <ProductThumbnail
        :class="{ animated: animated }"
        :product="product"
        :static="static"
        :isSelectable="isSelectable"
        :selected="selected"
        :isGift="isGift"
        :fullSize="fullSize"
        @click="$emit('click')"
      />
    </HMFixedAspectRatioContainer>
    <div class="outer-container d-flex flex-column align-items-start">
      <div class="description flex-grow-1 text-truncate">
        <div
          class="d-flex flex-column justify-content-between align-items-start"
        >
          <div class="product-name-container h4 m-0">
            <div class="product-name-clamp">{{ product.product_name }}</div>
          </div>
          <div
            class="product-progress-wrapper"
            v-if="typeof product.progress === 'number' && product.progress > 0"
          >
            <div class="product-progress">
              <div
                class="progress-bar"
                :style="{ width: product.progress + '%' }"
              ></div>
            </div>
            <small class="ms-2"> {{ parseInt(product.progress) }}% </small>
          </div>
          <div class="d-flex justify-content-between mt-2 c-dark-gray" v-if="!hidePrice">
            {{ getPricingText }}
          </div>
          <div class="footer c-dark-gray">
            <span
              class="wish-price smallest"
              v-if="product.wish_price_enabled && !hidePrice"
            >
              {{ $t('PRODUCTS.TEXT.PAY_WHAT_YOU_WISH_AVAILABLE') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import _ from 'lodash-es';
import moment from 'moment';
import ProductState, {
  getProductStateColor,
} from '@/utils/typings/enums/ProductState';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import { getProductTypeTranslations } from '@/utils/products';
import ProductThumbnail from '@/components/products/ProductThumbnail.vue';

export default {
  name: 'StudioProductCard',
  components: {
    // TextClamp,
    ProductThumbnail
  },
  props: {
    product: {
      type: Object as () => ProductResponse,
      required: true,
    },
    displayPricing: {
      type: Boolean,
      default: true,
    },
    static: {
      // removes any pricing information
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
    isGift: {
      type: Boolean,
      default: false,
    },
    fullSize: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    date: function (value: string): string {
      if (!value) return '';
      return moment(value).format('MMM DD, yyyy');
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      currency: 'currency',
    }),
    color(): string {
      return getProductStateColor(this.product.productState);
    },
    getProductType() {
      return getProductTypeTranslations(this.product.product_type);
    },
    getPricingText() {
      if (!this.displayPricing) {
        return null;
      }
      if (this.product.pricing_type === 'free') {
        return this.$t('PRODUCTS.LABELS.FREE');
      }
      if (this.product.pricing_type === 'fixed') {
        return this.$formatCurrency(this.product.price || 0, this.currency);
      }
      if (this.product.pricing_type === 'recurring') {
        let pricingText;
        let period;
        if (this.product.recurring_type !== 'multiple') {
          period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${this.product.recurring_type.toUpperCase()}`
          );
          pricingText = `${this.$formatCurrency(
            this.product.price || 0,
            this.currency
          )}`;
        } else {
          const prefix = this.$t('PRODUCTS.LABELS.FROM');
          const arr = _.values(
            _.transform(
              this.product.recurring_options,
              (result, value, key) => {
                result[key] = { ...value, period: key };
              }
            )
          );
          const onlyAvailable = arr.filter(opt => opt.available);
          const lowestOption = _.minBy(onlyAvailable, option =>
            parseFloat(option.price)
          );
          period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${lowestOption.period.toUpperCase()}`
          );
          pricingText = `${prefix} ${this.$formatCurrency(
            lowestOption.price || 0,
            this.currency
          )}`;
        }

        if (this.isGift) {
          return pricingText;
        } else {
          return `${pricingText}/${period}`;
        }
      }
      return 'ERROR';
    },
    hidePrice() {
      return this.static || this.product.purchased;
    },
    showWishPriceEnabled() {
      return this.product.wish_price_enabled && !this.hidePrice && !this.isGift;
    },
    hasTags() {
      return this.product?.tags?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.studio-product-card {
  &:hover {
    :deep(.image) {
      &.animated {
        box-shadow: 0 12px 12px -6px $dark-gray;
        transform: translateY(-16px);
      }
    }
    .product-name-container {
      color: var(--brand-color);
    }
  }

  cursor: pointer;
  position: relative;

  .description {
    position: relative;
    bottom: 0;
    left: 0;
    padding-top: 1rem;
    border-top-right-radius: 6px;
    width: 100%;
  }

  .product-name-container {
    white-space: normal;
    line-height: 1.5;
  }

  .heart {
    color: $coral;
  }
  .dot {
    margin-right: 0.25rem;
    margin-top: 0.3rem;
    font-size: 0.5rem;
  }
  .embed-responsive {
    overflow: visible;
  }

  .product-progress-wrapper {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .product-progress {
    width: 100%;
    border-radius: 6px;
    background-color: var(--brand-color-with-opacity-03);
  }

  .progress-bar {
    height: 18px;
    border-radius: 9px;
    background-color: var(--brand-color);
  }

  .footer {
    height: 3rem;
  }

  .product-name-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 60px;
    text-overflow: ellipsis;
  }
}
</style>
